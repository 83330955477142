export enum HealthPlanIdentifier {
  Aetna = 'org:ins:AETNA',
  Centene = 'org:ins:CENTENE',
  HSCSN = 'org:ins:HSCSN',
  Sunshine = 'org:ins:SUNSHINE',
  UnitedHealthcare = 'org:ins:UHC',
}

export enum MarketIdentifier {
  Texas = 'org:bus:Texas', 
  Florida = 'org:bus:Florida',
  WashingtonDC = 'org:bus:DC',
  NewYork = 'org:bus:New York',
}

export enum OrganizationType {
  Market = 'market',
  HealthPlan = 'health-plan',
}

export const organizationTypeMap: Record<OrganizationType, string> = {
  [OrganizationType.Market]: 'Market',
  [OrganizationType.HealthPlan]: 'Health Plan',
};
